'use strict';

import isEmpty from 'lodash/isEmpty';

import meetingView from './meeting.view.html';
import {MeetingController} from './meeting.controller.js';
import './meeting.style.scss';
import {uploadSessionAssetsModal} from './dialogs/upload-session-assets/upload-session-assets.service';
import {changeUrlModal} from './dialogs/change-url/change-url.service';
import {FlowType, MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

// Sub-states
import photoChatModule from './photo-chat/photo-chat.module.js';
import videoChatModule from './video-chat/video-chat.module.js';
import desktopSharingModule from './desktop-sharing/desktop-sharing.module';
import oneClickPhotoChatModule from './one-click-photo-chat/one-click-photo-chat.module.js';
import coBrowsingModule from './co-browsing/co-browsing.module.js';
import {getMeetingTracer} from './meeting.tracer';
import {
    DEFAULT_PEER_CONNECTIVITY_TIMEOUT_SECONDS,
    MediaServiceType,
    SessionClientRole
} from '@techsee/techsee-media-service/lib/MediaConstants';
import {AudioService} from './AudioService';
import {FeatureService} from '@techsee/techsee-client-infra/lib/services/FeatureService';
import {STATUS_MESSAGES} from './meeting.settings';
import {LOADER_STATES} from '../../../loader/loader.settings';
import {getBrowserInfo} from '../../../../shared/browser-info.js';
import {SmartGlassesService} from '@techsee/techsee-client-services/lib/services/SmartGlassesService';
import {getSubDomainFromUrl} from '@techsee/techsee-common/lib/utils';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getRootStore} from '../../_react_/app.bootstrap';
import {MobileAppMediaService} from '../../_react_/services/media-service-api/MobileAppMediaService';

const trace = getMeetingTracer('MeetingModule');

function config($stateProvider) {
    $stateProvider.state('meeting', {
        url: BASE_PATH + '?room&roomCode&g&csi&t&media-service&desktop-sharing',
        template: meetingView,
        controller: 'MeetingController',
        controllerAs: 'meeting',
        resolve: {
            currentUser: (db) => {
                'ngInject';

                return db.User.find('current')
                    .then((user) => {
                        getRootStore().roomChannelTracer.setCurrentUserId(user._id);

                        return user;
                    })
                    .catch(() => ({}));
            },

            sessionData: (db, currentUser) => {
                const guid = getRootStore().urlUtils.getParamValue('g');

                if (!guid) {
                    return {
                        params: {}
                    };
                }

                return new Promise((resolve) => {
                    db.Shorturl.find(guid)
                        .then((entry) => {
                            if (entry) {
                                entry.guid = guid;

                                if (entry.params && entry.params.sw === MeetingMode.faceMeet.toString().toLowerCase()) {
                                    return getRootStore().stateHelper.safeGo('newMeeting', {
                                        g: guid,
                                        room: getRootStore().urlUtils.getParamValue('room'),
                                        roomCode: getRootStore().urlUtils.getParamValue('roomCode'),
                                        csi: getRootStore().urlUtils.getParamValue('csi'),
                                        t: getRootStore().urlUtils.getParamValue('t')
                                    });
                                }
                            }

                            resolve(entry);
                        })
                        .catch((err) => {
                            getRootStore().eventService.sendEventLog(
                                currentUser && currentUser._id,
                                'none',
                                STATUS_MESSAGES.CUSTOMER_LOADING_SHORT_URL_ERROR,
                                {error: err, guid, errorLocation: 'Failed to find shortUrl | meeting module'}
                            );

                            if (err.status === 404) {
                                return getRootStore().stateHelper.safeGo('endNew', {expiredRoom: true});
                            }

                            resolve({
                                params: {},
                                err
                            });
                        });
                });
            },

            urlData: () => ({
                room: getRootStore().urlUtils.getParamValue('room'),
                roomCode: getRootStore().urlUtils.getParamValue('roomCode'),
                intent: getRootStore().urlUtils.getParamValue('intent'),
                allowOneClick: getRootStore().urlUtils.getParamValue('allow-oneclick'),
                referrer: getRootStore().urlUtils.getParamValue('ref'),
                usingApplication: getRootStore().environmentDetect.isApplicationWebView(
                    getRootStore().urlUtils.getParamValue('ref')
                ),
                mediaService: getRootStore().urlUtils.getParamValue('media-service'),
                flowType: getRootStore().urlUtils.getParamValue('flow-type'),
                enforceAuth: getRootStore().urlUtils.getParamValue('ea'),
                desktopSharing: getRootStore().urlUtils.getParamValue('desktop-sharing'),
                audio: getRootStore().urlUtils.getParamValue('audio')
            }),

            smartGlasses: () => {
                return getBrowserInfo().then((info) => {
                    if (SmartGlassesService.isSmartGlasses(info.userAgent)) {
                        SmartGlassesService.addFocusHoverAssist();
                    }
                });
            },
            initBranding: () => {
                const subdomain = getSubDomainFromUrl(window.location.hostname);

                return getRootStore().brandingService.setAccountBranding(subdomain, PlatformType.mobile_web);
            },
            roomInfo: (sessionData, urlData, currentUser) => {
                if (getRootStore().browserUtilsService.getFromSessionStorage('shouldEndSession')) {
                    return getRootStore().stateHelper.safeGo('endNew');
                }

                const roomInfo = {
                    roomId: urlData.room || sessionData.params.room,
                    roomCode: urlData.roomCode || sessionData.params.roomCode,
                    intent: urlData.intent || sessionData.params.intent,
                    allowOneClick: urlData.allowOneClick || sessionData.params['allow-oneclick'],
                    mediaServiceType: urlData.mediaService || sessionData.params['media-service'],
                    referrer: urlData.referrer,
                    usingApplication: urlData.usingApplication,
                    flowType: urlData.flowType || sessionData.params['flow-type'],
                    enforceAuth: urlData.ea || sessionData.params.ea,
                    desktopSharing: urlData.desktopSharing || sessionData.params['desktop-sharing'],
                    audio: urlData.audio || sessionData.params.audio
                };

                if (!roomInfo.roomId && !roomInfo.roomCode) {
                    return getRootStore().stateHelper.safeGo('start.main');
                }

                if (roomInfo.enforceAuth !== 'no' && roomInfo.flowType === FlowType.fs && isEmpty(currentUser)) {
                    const guid = getRootStore().urlUtils.getParamValue('g');

                    return getRootStore().stateHelper.safeGo('fs', {g: guid});
                }

                return roomInfo;
            },
            webRTCSupport: (currentUser, roomInfo) => {
                'ngInject';

                return getRootStore()
                    .webRtcDetector.getWebRtcInfo()
                    .then((webRTCSupportResult) => {
                        getRootStore().chatApi.setWebRTCSupport(webRTCSupportResult);

                        return webRTCSupportResult;
                    })
                    .catch((err) => {
                        getRootStore().eventService.sendEventLog(
                            currentUser && currentUser._id,
                            roomInfo && roomInfo.roomId,
                            STATUS_MESSAGES.CUSTOMER_LOADING_WEBRTC_ERROR,
                            {error: err, errorLocation: 'webRTCSupport failure | meeting module'}
                        );

                        return getRootStore().stateHelper.safeGo('endNew');
                    });
            },
            featureService: () => {
                const featureService = new FeatureService(getRootStore().environmentDetect);

                getRootStore().chatApi.on(socketEvents.CLIENT_IN.SYNC, (settings) => {
                    if (settings) {
                        featureService.setAccountSettings(settings);
                    }
                });

                return featureService;
            },
            mobileAppMediaService: (webRTCSupport, roomInfo, currentUser) => {
                'ngInject';
                trace.info('Init mobileAppMediaService');

                const service = new MobileAppMediaService(
                    getRootStore().environmentDetect,
                    webRTCSupport,
                    getRootStore().browserUtilsService
                );
                const options = {
                    clientRole: SessionClientRole.USER,
                    mediaServiceType: roomInfo.mediaServiceType,
                    peerConnectivityTimeoutSeconds: DEFAULT_PEER_CONNECTIVITY_TIMEOUT_SECONDS
                };

                return service
                    .initMediaService(options)
                    .then(() => service)
                    .catch((err) => {
                        getRootStore().eventService.sendEventLog(
                            currentUser && currentUser._id,
                            roomInfo && roomInfo.roomId,
                            STATUS_MESSAGES.CUSTOMER_LOADING_MEDIA_SERVICE_INITIATION_ERROR,
                            {error: err, errorLocation: 'mobileAppMediaService failure | meeting module'}
                        );

                        return getRootStore().stateHelper.safeGo('endNew');
                    });
            },
            audioService: (mobileAppMediaService, currentUser, roomInfo) => {
                'ngInject';
                const enableAudioForObserver =
                    roomInfo.mediaServiceType === MediaServiceType.MEDIASERVER ||
                    roomInfo.mediaServiceType === MediaServiceType.OPENTOK;

                const tsEventService = getRootStore().eventService;

                try {
                    return new AudioService(
                        getRootStore().chatApi,
                        mobileAppMediaService,
                        currentUser,
                        roomInfo.audio === 'yes',
                        enableAudioForObserver
                    );
                } catch (err) {
                    tsEventService.sendEventLog(
                        currentUser && currentUser._id,
                        roomInfo && roomInfo.roomId,
                        STATUS_MESSAGES.CUSTOMER_LOADING_AUDIO_SERVICE_INITIATION_ERROR,
                        {error: err, errorLocation: 'Failed to initiate Audio service | meeting module'}
                    );

                    return getRootStore().stateHelper.safeGo('endNew');
                }
            },
            verboseLogs: (sessionData, roomInfo, currentUser) => {
                if (sessionData && sessionData.params['verbose-logging']) {
                    try {
                        getRootStore().eventService.setRoomDetails(roomInfo.roomId, roomInfo.roomCode);
                    } catch (err) {
                        getRootStore().eventService.sendEventLog(
                            currentUser && currentUser._id,
                            roomInfo && roomInfo.roomId,
                            STATUS_MESSAGES.CUSTOMER_LOADING_VERBOS_LOGS_ERROR,
                            {error: err, errorLocation: 'Failed to initiate VerboseLogs | meeting module'}
                        );
                    }
                }
            },
            setMeetingStarted: () => {
                window.loaderState = LOADER_STATES.MEETING_STARTED;
            }
        },
        onExit: (mobileAppMediaService, currentUser, roomInfo) => {
            'ngInject';

            mobileAppMediaService
                .dispose()
                .then(() => {
                    trace.info('meeting.module.onExit success');
                })
                .catch((error) => {
                    getRootStore().eventService.sendEventLog(
                        currentUser && currentUser._id,
                        roomInfo && roomInfo.roomId,
                        STATUS_MESSAGES.CUSTOMER_LOADING_MEDIA_SERVICE_DISPOSE_ERROR,
                        {error, errorLocation: 'meeting.module.onExit'}
                    );

                    trace.warn('Error in meeting.module.onExit', error);
                });
        }
    });
}

export default angular
    .module('states.meeting', [
        photoChatModule.name,
        videoChatModule.name,
        oneClickPhotoChatModule.name,
        coBrowsingModule.name,
        desktopSharingModule.name
    ])
    .config(config)
    .controller('MeetingController', MeetingController)
    .service('uploadSessionAssetsModal', uploadSessionAssetsModal)
    .service('changeUrlModal', changeUrlModal);
